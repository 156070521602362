import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>
          Our Services | Explore Our Services at LuxeWheelz Premier Car Rentals
        </title>
        <meta
          name={"description"}
          content={"Tailored to Elevate Your Driving Experience\n"}
        />
        <meta
          property={"og:title"}
          content={
            "Our Services | Explore Our Services at LuxeWheelz Premier Car Rentals"
          }
        />
        <meta
          property={"og:description"}
          content={"Tailored to Elevate Your Driving Experience"}
        />
        <meta
          property={"og:image"}
          content={"https://yexonovaplus.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yexonovaplus.com/img/car.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yexonovaplus.com/img/car.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yexonovaplus.com/img/car.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        background="#EDF2F6"
        sm-padding="60px 0 60px 0"
        quarkly-title="Schedule-8"
      >
        <Override
          slot="SectionContent"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px 30px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="30px"
          md-grid-gap="32px"
        />
        <Box
          flex-direction="column"
          md-width="100%"
          md-align-items="center"
          md-justify-content="center"
          md-text-align="center"
          align-items="center"
          display="flex"
          grid-column="1 / span 3"
          lg-grid-column="auto / auto"
        >
          <Text
            margin="0px 0px 16px 0px"
            font="--headline1"
            color="--dark"
            md-text-align="center"
            sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            text-align="center"
          >
            Comprehensive Range of Services
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--darkL2"
            md-text-align="center"
            text-align="center"
            max-width="800px"
            sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
          >
            We offer exceptional services to enhance your luxury car rental
            experience. At LuxeWheelz, we strive to provide more than just a
            vehicle – we deliver a comprehensive service package designed to
            make every aspect of your rental seamless and superior.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 14px 0px"
            color="--darkL2"
            font="--headline3"
            lg-text-align="left"
          >
            Our Offerings
          </Text>
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Pre-Trip Consultation: Discuss your travel plans with our experts
            who can tailor your itinerary to include optimal routes and
            must-visit spots.
            <br />
            <br />
            Customized Recommendations: Receive personalized suggestions for
            dining, entertainment, and leisure to enhance your journey.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Meet and Greet: Be welcomed by a LuxeWheelz representative upon
            arrival who will guide you to your vehicle.
            <br />
            <br />
            Luggage Assistance: Allow us to take care of your luggage from the
            airport to the car for a hassle-free start to your trip.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Professional Drivers: Experience the comfort of being driven by a
            professional chauffeur trained to provide an exceptional service.
            <br />
            <br />
            Flexible Scheduling: Book a chauffeur for a few hours or the entire
            duration of your rental, tailored to your needs.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Corporate Accounts: Benefit from tailored rental programs designed
            to meet the needs of business travelers.
            <br />
            <br />
            Event Transportation: Ensure seamless transport logistics for
            conferences, meetings, or corporate retreats.
          </Text>
        </Box>
        <Box
          padding="40px 40px 40px 40px"
          lg-padding="45px 30px"
          md-padding="45px 45px"
          background="#FFFFFF"
          border-radius="24px"
          justify-content="flex-start"
          flex-direction="column"
          display="flex"
        >
          <Text
            margin="0px 0px 46px 0px"
            color="--greyD3"
            font="--base"
            lg-text-align="left"
            flex="1 0 auto"
          >
            Enhanced In-Car Amenities: Enjoy upgraded amenities such as premium
            audio systems, Wi-Fi, and climate-controlled interiors.
            <br />
            <br />
            Special Requests: From child safety seats to luxury picnic hampers,
            request additional items to enhance your rental experience.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://yexonovaplus.com/img/3.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Learn More and Book Your Experience
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            At LuxeWheelz, we are committed to providing an unparalleled level
            of service that reflects the luxury and quality of our fleet. For
            more detailed information on any of our services or to discuss
            specific requirements for your next rental, please reach out to us.
            Our dedicated team is ready to assist you in crafting a perfectly
            tailored luxury car rental experience.
            <br />
            <br />
            Drive luxuriously with LuxeWheelz. Reach out today to discover how
            we can transform your travel plans into an unforgettable luxury
            experience.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
